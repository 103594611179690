

class SetStore{

    constructor(){
        this.store = [];

        this.currentSetId = 200;
        this.currentSongId = 20;
    
        this.loadSetStore();

        this.selectedSetIndex = 0;

        this.updateCallbacks = [];
    }

    saveSetStore(){
        localStorage.setItem('MD_TOOL_DATA', JSON.stringify(this.store));
    }

    loadSetStore(){
        let storageText = localStorage.getItem('MD_TOOL_DATA');
        if(storageText){
            try{
                this.store = JSON.parse(storageText);
            }catch(e){}
        }

        let maxSet = 0;
        let maxSong = 0;

        this.store.forEach( set => {
            if(set.id > maxSet)maxSet = set.id;
            set.songs.forEach(song => {
                if(song.id > maxSong)maxSong = song.id;
            });
        })
        
        this.currentSetId = maxSet+1;
        this.currentSongId = maxSong+1;
    }

    clearSetStore(){
        this.store = [];
        this.update();
    }

    loadTestData(){
        this.store = [
            {id: 198, name: 'Worship Set Robin', 
                songs: [{id: 15, title: "Herr lebe in uns", bpm: 60 },{id: 18, title: "Segne uns o Herr", bpm: 91 },{id: 19, title: "Grenzenlos", bpm: 120 }]
            },
            {id: 199, name: 'Worship Set JD', 
                songs: [{id: 16, title: "Herr komm!", bpm: 60 },{id: 17, title: "Segne ", bpm: 91 }] 
            }
        ];
        this.update();
    }

    update(){
        this.saveSetStore();
        this.emitUpdateCallbacks();
    }

    addSet( setName ){
        this.update();
        this.store.push(
            {id: this.currentSetId++, name: setName, songs: [] }
        );
    }

    deleteSet( setId ){
        this.store = this.store.filter( set => set.id != setId);
        this.selectedSetIndex = 0;
        this.update();
    }

    getAllSets(){
        return this.store;
    }

    selectSet( setId ){
        this.store.forEach( (set, index) => {
            if(parseInt(setId) === set.id){
                this.selectedSetIndex = index;
            }
        });
        this.update();
        return this.getSet();
    }

    getSet(){
        return this.store[this.selectedSetIndex];
    }

    getSongs(){
        return this.getSet().songs;
    }

    addSong(songTitle, bpm){
        let set = this.getSet();

        set.songs.push(
            {id: this.currentSongId++, title: songTitle, bpm: bpm}
        );
        this.update();
    }

    deleteSong(songId){
        this.store[this.selectedSetIndex].songs = this.store[this.selectedSetIndex].songs.filter( song => {
            return song.id != songId;
        });
        this.update();
    }

    addUpdateCallback( callback ){
        this.updateCallbacks.push( callback );
        this.update();
    }

    emitUpdateCallbacks(){
        this.updateCallbacks.forEach( callback => { callback(); });
    }
}

export default SetStore;