<template>
  <f7-page name="catalog">
    <f7-navbar title="Set-List"></f7-navbar>
    <f7-list>
      <f7-list-item
        v-for="(set, index) in data"
        :key="set.id"
        :title="set.name"
        :link="`/set/${set.id}/`"
      >
      <f7-link @click="deleteWorshipSet(index)" :no-link-class="false">
        <f7-icon slot="after" f7="trash"></f7-icon>
      </f7-link>   
    </f7-list-item>
    </f7-list>

    <f7-list no-hairlines-md inset>
      <f7-list-input label="Name" type="text" :value="newSetName" @input="newSetName = $event.target.value" placeholder="Worship Set II"></f7-list-input>
      <f7-list-button title="Set anlegen" outline @click="createWorshipSet"></f7-list-button>
    </f7-list>
  </f7-page>
</template>
<script>
  export default {
    data: function () {
      return {
        data: [],
        newSetName: "",
      };
    },
    mounted(){
//      this.data = this.$setStore.getAllSets();
      this.$setStore.addUpdateCallback( () => {
        this.data = this.$setStore.getAllSets();
      });
    },
    methods: {
      deleteWorshipSet(arrayIndex){
        this.$setStore.deleteSet( this.data[arrayIndex].id );
      },
      createWorshipSet(){
        this.$setStore.addSet(this.newSetName);
        this.newSetName = "";
      }
    }
  };
</script>
