<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>Music Director Tool</f7-nav-title>
      <f7-nav-title-large>Music Director Tool</f7-nav-title-large>
    </f7-navbar>

  </f7-page>
</template>
<script>
export default {
}
</script>