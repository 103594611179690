<template>
<div>
    <f7-block-title>BPM</f7-block-title>
    <f7-block>
        <f7-link v-for="(song, index) in data" :key="song.id" @click="clickSong(index)" :no-link-class="true">
            <f7-card>
                <div class="bg-color-red">
                    
                    <f7-card-content :padding="false" :style="{height: '100px'}">
                        <f7-card-header text-color="white" class="display-block">
                            {{ song.title }}
                            <br />
                            <small :style="{opacity: 0.7}">{{ song.bpm }} BPM</small>
                        </f7-card-header>
                    </f7-card-content>
                    <f7-card-footer style="display: flex; justify-content: right;">
                        <f7-link icon-f7="trash" style="color: white; text-align: right;" @click="deleteSong(index)"></f7-link>
                    </f7-card-footer>
                </div>
            </f7-card>
        </f7-link>
    </f7-block>
</div>
</template>
<script>
export default {
    data(){
        return {
            data: [],
        };
    },
    mounted(){
        this.loadSongs();

        this.$setStore.addUpdateCallback( this.loadSongs );
    },
    methods: {
        clickSong: function( arrayIndex ){          
            this.$eventHub.$emit('metronom-start', this.data[arrayIndex]);
        },
        loadSongs: function(){
            this.data = this.$setStore.getSongs();
        },
        deleteSong: function(songIndex){
            this.$setStore.deleteSong( this.data[songIndex].id );
        }
    }
}
</script>