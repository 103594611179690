<template>
    <f7-list no-hairlines-md inset>
      <f7-list-input label="Name" type="text" :value="name" @input="name = $event.target.value" placeholder="Here i am to Worship"></f7-list-input>
      <f7-list-input label="BPM" type="text" :value="bpm" @input="bpm = $event.target.value" placeholder="120"></f7-list-input>
      <f7-list-button title="Song anlegen" outline @click="createSong"></f7-list-button>
    </f7-list>
</template>
<script>
export default {
    data(){
        return {
            name: "",
            bpm: "",
        };
    },
    methods: {
        createSong: function(){
            if(this.name == "" || this.name == null){
                this.$f7.dialog.alert('Name darf nicht leer sein!');
                return;
            }
            if(parseInt(this.bpm) == 0 || this.bpm == ""){
                this.$f7.dialog.alert('BPM darf nicht leer sein!');
                return;
            }

            this.$setStore.addSong(this.name, parseInt(this.bpm));
            this.name = "";
            this.bpm = "";
        }
    }
}
</script>