<template>
<div>
    <f7-block-title>Metronom</f7-block-title>
    <f7-block strong inset>
        <f7-row>
            <f7-col>
                <f7-button large fill @click="togglePlaying">
                    <f7-icon :f7="(isPlaying ? 'pause_fill' : 'play_fill')"></f7-icon>
                </f7-button>
            </f7-col>
            <f7-col>
                <p class="text-align-right">{{ bpm }} BPM</p>
            </f7-col>
        </f7-row>
        <f7-row style="margin-top: 1rem;"> 
            <f7-col><f7-button :fill="( tick == 1)" outline raised></f7-button></f7-col>
            <f7-col><f7-button :fill="( tick == 2)" outline></f7-button></f7-col>
            <f7-col><f7-button :fill="( tick == 3)" outline></f7-button></f7-col>
            <f7-col><f7-button :fill="( tick == 4)" outline></f7-button></f7-col>
        </f7-row>
    </f7-block>
</div>
</template>
<script>

  export default {
    data() {
      return {
          isPlaying: false,
          bpm: 120,
          tick: 1, /*1 ... 4*/
          interval: null,
      }
    },
    methods: {
        togglePlaying: function(){
            if(this.isPlaying){
                this.clearInterval();
            }else{
                this.initInterval();
            }
        },
        initInterval: function(){
            this.isPlaying = true;
            let time = (1 / this.bpm ) * 60 * 1000;

            this.interval = setInterval( () => {
                this.doTick();
            }, time);
            this.doTick();
        },
        clearInterval: function(){            
            this.isPlaying = false;

            clearInterval(this.interval);
            this.tick = 0;
        },
        doTick(){
            if(this.tick === 4){
                this.tick = 0;
            }
            this.tick += 1;
        }
    },
    mounted() {
        this.$eventHub.$on('metronom-start', song => {
            this.clearInterval();
            this.bpm = song.bpm;
            this.initInterval();
        });
    }
  }
</script>