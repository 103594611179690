<template>
  <f7-page name="set">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-f7="arrow_left" back></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>{{ title }}</f7-nav-title>
    </f7-navbar>

    <metronom></metronom>

    <song-list></song-list>
  
    <create-song></create-song>

    <br><br><br><br>
  </f7-page>
</template>
<script>
import SongList from '../components/song-list.vue';
import Metronom from '../components/metronom.vue';
import CreateSong from '../components/create-song.vue';

export default {
  components:{
    'song-list': SongList,
    'metronom': Metronom,
    'create-song': CreateSong,
  },
  data() {
    return {
      title: ""
    };
  },
  mounted(){
    if(this.$f7route.params.hasOwnProperty('id')){
      this.$setStore.selectSet(this.$f7route.params.id);
      this.title = this.$setStore.getSet().name;
    }
  }
}
</script>